import activeCases from '@/api/activeCases/activeCases';

// State object
const state = {
    activeQueues: [],
    activeCases: [],
    activeDialogs: [],
    unreadMessages: 0,
    activeCapacity: {},
};

// Actions
const actions = {
    /**
     * @param {object} data
     * @param {string} data.action - insert  | delete
     * @param {string} data.caseId - caseId
     * @param {number} data.unreadMessages - unreadMessages
     * @param {string} data.type - type - chat | email | call
     */
    SOCKET_activeDialogs({ commit }, data) {
        if (data.action === 'insert') {
            const divertSettings = state.activeCapacity.divertSettings;
            const totalWeight = divertSettings[0].multiplier * 100;
            const divertSetting = divertSettings.find((setting) => setting.channelType === data.type);

            if (divertSetting) {
                const effectiveWeight = divertSetting.amount * divertSetting.weight;

                commit('ADD_ACTIVE_CAPACITY_ITEM', {
                    id: data.caseId,
                    caseId: data.caseId,
                    unreadMessages: 0,
                    capacityPercentage: Math.round((effectiveWeight / totalWeight) * 100),
                });
            }
        }

        if (data.action === 'delete') {
            commit('REMOVE_ACTIVE_CAPACITY_ITEM', data.caseId);
        }
    },

    SOCKET_activeUnreadMessages({ state, commit }, data) {
        const { unreadMessagesChange, unreadMessages, caseId } = data;
        commit('SET_UNREAD_MESSAGES', state.unreadMessages + unreadMessagesChange);
        commit('UPDATE_UNREAD_MESSAGES_ITEM', { caseId, unreadMessages });
        commit('UPDATE_ACTIVE_CAPACITY_ITEM', { caseId, unreadMessages });
    },

    subscribeToUnreadMessages({ rootState }) {
        this._vm.$socket.emit('subscribe', [`activeUnreadMessages_${rootState.Auth.userObject.userId}`]);
    },

    unsubscribeFromUnreadMessages({ rootState }) {
        this._vm.$socket.emit('unsubscribe', [`activeUnreadMessages_${rootState.Auth.userObject.userId}`]);
    },

    subscribeToActiveDialogs({ rootState }) {
        this._vm.$socket.emit('subscribe', [`activeDialogs_${rootState.Auth.userObject.userId}`]);
    },

    unsubscribeFromActiveDialogs({ rootState }) {
        this._vm.$socket.emit('unsubscribe', [`activeDialogs_${rootState.Auth.userObject.userId}`]);
    },

    async getActiveCases({ commit }, { limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await activeCases.getActiveCases(limit, offset);
            if (page === 1) {
                commit('SET_ACTIVE_CASES', response.data.items);
            } else {
                commit('SET_ACTIVE_CASES', state.activeCases.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getActiveDialogs({ commit }, { limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await activeCases.getActiveDialogs(limit, offset);
            if (page === 1) {
                commit('SET_ACTIVE_DIALOGS', response.data.items);
            } else {
                commit('SET_ACTIVE_DIALOGS', state.activeDialogs.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getActiveQueues({ commit }, { limit, page }) {
        try {
            const offset = (page - 1) * limit;
            const response = await activeCases.getActiveQueues(limit, offset);
            if (page === 1) {
                commit('SET_ACTIVE_QUEUES', response.data.items);
            } else {
                commit('SET_ACTIVE_QUEUES', state.queues.concat(response.data));
            }
        } catch (error) {
            console.log(error);
        }
    },

    async getActiveUnreadMessages({ commit }) {
        try {
            const response = await activeCases.getActiveUnreadMessages();
            commit('SET_UNREAD_MESSAGES', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async getActiveCapacity({ dispatch, commit }) {
        try {
            dispatch('unsubscribeFromActiveDialogs');
            const response = await activeCases.getActiveCapacity();
            commit('SET_ACTIVE_CAPACITY', response.data);
            dispatch('subscribeToActiveDialogs');
        } catch (error) {
            console.log(error);
        }
    },
};
// Mutations
const mutations = {
    SET_ACTIVE_CASES(state, activeCases) {
        state.activeCases = activeCases;
    },

    SET_ACTIVE_DIALOGS(state, activeDialogs) {
        state.activeDialogs = activeDialogs;
    },

    SET_ACTIVE_QUEUES(state, activeQueues) {
        state.activeQueues = activeQueues;
    },

    SET_UNREAD_MESSAGES(state, unreadMessages) {
        state.unreadMessages = unreadMessages;
    },

    SET_ACTIVE_CAPACITY(state, activeCapacity) {
        state.activeCapacity = activeCapacity;
    },

    UPDATE_UNREAD_MESSAGES_ITEM(state, { caseId, unreadMessages }) {
        const activeCase = state.activeCases.find((activeCase) => activeCase.caseId === caseId);
        if (!activeCase) return;
        activeCase.unreadMessages = unreadMessages;
    },

    ADD_ACTIVE_CAPACITY_ITEM(state, activeCapacityItem) {
        state.activeCapacity.items.push({
            id: activeCapacityItem.caseId,
            caseId: activeCapacityItem.caseId,
            capacityPercentage: activeCapacityItem.capacityPercentage || 0,
            unreadMessages: activeCapacityItem.unreadMessages || 0,
        });
    },

    REMOVE_ACTIVE_CAPACITY_ITEM(state, caseId) {
        state.activeCapacity.items = state.activeCapacity.items.filter(
            (activeCapacityItem) => activeCapacityItem.id !== caseId,
        );
    },

    UPDATE_ACTIVE_CAPACITY_ITEM(state, { caseId, unreadMessages }) {
        const activeCapacityItems = state.activeCapacity?.items;
        if (!activeCapacityItems) return;

        const activeCapacity = activeCapacityItems.find((activeCapacity) => activeCapacity.id === caseId);
        if (!activeCapacity) return;

        activeCapacity.unreadMessages = unreadMessages;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
