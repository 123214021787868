import axios from 'axios';

function getActiveCases(limit, offset) {
    return axios.get('/active/cases', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveDialogs(limit, offset) {
    return axios.get('/active/dialogs', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveQueues(limit, offset) {
    return axios.get('/active/queues', {
        params: {
            limit,
            offset,
        },
    });
}

function getActiveUnreadMessages() {
    return axios.get('/active/unreadmessages');
}

function getActiveCapacity() {
    return axios.get('/active/capacity');
}

export default {
    getActiveCases,
    getActiveDialogs,
    getActiveQueues,
    getActiveUnreadMessages,
    getActiveCapacity,
};
