import v2Api from '@/api/templates/systemtemplates';

import { replaceTemplateValues } from '@/utils';

import api from '@/api/templates/templates';
import i18n from '@/i18n';

// State object
const state = {
    templates: {
        casesTemplates: [],
        selectedTemplate: {
            id: 0,
            attachments: [],
        },
    },
};
// Getter functions
const getters = {};
// Actions
const actions = {
    /**
     * Transforms the content of a template by replacing merge tags with their corresponding values.
     * @param {Object} rootState - The root state object.
     * @param {Object} template - The template object to transform.
     * @param {Object} context - The context object.
     * @returns {Object} - The transformed template object.
     */
    transformContentMergeTags({ rootState }, { template, context }) {
        // Determine the sender based on context
        const sender = context.includes('EMAIL_TEMPLATE_SELECT')
            ? rootState.Creator.currentFormData?.form?.inputs?.find((input) => input.type === 'EMAIL_INPUT_SYSTEM')
                  ?.value || ''
            : rootState.Comments.emailFrom;

        // Get the agent information from the auth state
        const agent = rootState.Auth.userObject;

        // Transform the template content by replacing merge tags
        template.content = replaceTemplateValues(template.content, { agent, sender });

        return template;
    },

    selectTemplate({ commit }, template) {
        if (template.id !== state.templates.selectedTemplate.id) {
            commit('SET_SELECTED_TEMPLATE', template);
        } else {
            commit('SET_SELECTED_TEMPLATE', { id: 0 });
        }
    },
    /**
     * Retrieves templates by category.
     *
     * @param {object} state - The state object.
     * @param {string} categoryId - The category ID.
     * @returns {object[]} - An array of templates.
     */
    async templateByCategory({ state, commit }, { page, limit, categoryId, search, queueIds, language }) {
        const offset = (page - 1) * limit;
        try {
            const res = await v2Api.templateByCategory(categoryId, { offset, limit, search, language, queueIds });
            if (res.status === 200 && Array.isArray(res.data)) {
                if (page === 1) {
                    commit('SET_TEMPLATES', res.data);
                } else {
                    commit('SET_TEMPLATES', state.templates.casesTemplates.concat(res.data));
                }
            } else {
                throw new Error(res.data?.message || 'Unexpected error occurred');
            }
            return res.data;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },
    /**
     * Retrieves a template by its ID.
     *
     * @param {Object} _ - The Vuex context object.
     * @param {string} templateId - The ID of the template to retrieve.
     * @returns {Promise<Object|null>} - A promise that resolves to the retrieved template object, or null if an error occurred.
     */
    async getTemplateById({ _ }, templateId) {
        try {
            const res = await v2Api.getTemplateById(templateId);
            if (res.status === 200 && res.data) {
                return { ...res.data.result, attachments: res.data.attachments };
            }

            throw new Error(res.data?.message || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
        }
    },

    /**
     * Performs a search for template suggestions based on the provided category, page, limit, and search term.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload for the search.
     * @param {number} payload.categoryId - The ID of the category to search within.
     * @param {number} payload.page - The page number for pagination.
     * @param {number} payload.limit - The number of items per page.
     * @param {string} payload.search - The search term to filter results.
     * @returns {Promise<Array>} A promise that resolves to an array of search results.
     * @throws {Error} Throws an error if the search fails.
     */
    async suggestionSearch({ _ }, { categoryId, page, limit, search, queueIds }) {
        const offset = (page - 1) * limit;
        try {
            const res = await v2Api.templateByCategory(categoryId, { offset, limit, search, queueIds });
            if (res.status === 200 && Array.isArray(res.data)) {
                return res.data;
            }

            throw new Error(res.data?.message || 'Unexpected error occurred');
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },

    async getTemplateAttachments({ _ }, templateId) {
        try {
            const res = await api.getTemplateAttachments(templateId);

            return res.data;
        } catch (error) {
            this._vm.$toasted.show(error, {
                icon: 'cancel',
                type: 'error',
            });
            return [];
        }
    },
};

const mutations = {
    /**
     * Sets the selected template in the state.
     *
     * @param {Object} state - The current state of the store.
     * @param {Object} template - The template to be set as selected.
     */
    SET_SELECTED_TEMPLATE(state, template) {
        state.templates.selectedTemplate = template;
    },
    /**
     * Sets the templates in the state.
     *
     * @param {Object} state - The Vuex state object.
     * @param {Array} templates - The templates to be set in the state.
     */
    SET_TEMPLATES(state, templates) {
        state.templates.casesTemplates = templates;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
