<template>
    <div v-if="timelineExists" class="virtual-container">
        <article
            v-for="(item, index) in timelineDataWithDates"
            :key="item.data?.caseId || item.month + '_' + index"
            class="scroll-item"
        >
            <div v-if="item.type === 'month'" class="month">
                <div class="month-text">{{ getMonthByNumber(item.month) }}</div>
            </div>
            <div v-else>
                <ActivityLine :item="item.data" :border="false" />

                <article class="item-wrapper" @click="goTo(item.data.caseId)">
                    <v-tooltip top open-delay="500">
                        <template #activator="{ on, attrs }">
                            <div class="item">
                                <div class="type-icon">
                                    <InternalType :item="item.data" :small="true" />
                                </div>
                                <div class="card-wrapper">
                                    <section class="timeline-header-wrapper" v-bind="attrs" v-on="on">
                                        <article class="text-truncate timeline-heading">
                                            {{ getTitle(item.data) }}
                                        </article>
                                        <article class="item-date">
                                            {{ formatDateToText(item.data.dateTime, locale) }}
                                        </article>
                                    </section>

                                    <div class="card-icons">
                                        <v-tooltip bottom>
                                            <template #activator="{ on: innerOn, attrs: innerAttrs }">
                                                <div
                                                    class="rounded-circle"
                                                    :style="`background-color: ` + getStatusColor(item.data.status)"
                                                    v-bind="innerAttrs"
                                                    v-on="innerOn"
                                                >
                                                    <v-icon size="12" color="white">
                                                        {{ statusIcons[item.data.status] }}
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <span>{{ statusText(item.data.status) }}</span>
                                        </v-tooltip>
                                    </div>

                                    <div class="card-avatar">
                                        <v-tooltip bottom>
                                            <template #activator="{ on: innerOn, attrs: innerAttrs }">
                                                <div v-bind="innerAttrs" v-on="innerOn">
                                                    <CommonAvatar
                                                        :key="item.data.userId"
                                                        :userId="item.data.userId"
                                                        :size="24"
                                                    />
                                                </div>
                                            </template>
                                            <span class="font-username">
                                                {{ item.data.userName || $t('timeLine.noAgent') }}
                                            </span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div className="text-subtitle-1">{{ getTitle(item.data) }}</div>
                        <div className="text-subtitle-2">{{ formatDateToText(item.data.dateTime, locale) }}</div>
                    </v-tooltip>
                </article>
            </div>
        </article>
    </div>

    <div
        v-else
        :style="{ height: verticalHeight }"
        class="d-flex flex-column justify-center align-center loader-container"
    >
        <v-progress-circular :size="40" color="primary" indeterminate />
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import { useEventListener } from '@vueuse/core';
    import InternalType from '@/components/Cases/List/InternalType.vue';
    import CommonAvatar from '@/components/Global/CommonAvatar.vue';
    import ActivityLine from '@/components/Cases/List/ActivityLine.vue';

    import { formatDateToText, getMonthByNumber } from '@/utils/DateFormatter';
    import { filterObjectsByKeys, getActiveFromSettings } from '@/utils';

    import { caseStatuses } from '@/enums/cases.enums';
    import { statusTypes, channelTypes } from '../../templates/cases.template';

    export default {
        components: {
            CommonAvatar,
            InternalType,
            ActivityLine,
        },
        props: {
            caseId: {
                type: Number,
                default: 0,
            },
        },

        data() {
            return {
                statusIcons: Object.freeze({
                    Closed: 'mdi-tray-minus',
                    Open: 'mdi-tray',
                    Ongoing: 'mdi-tray-full',
                    Deleted: 'mdi-tray-remove',
                    InQueue: 'mdi-arrow-down',
                }),
                colors: this.$vuetify.theme.themes.light,
                verticalHeight: '480px',
                numberOfItems: 7,
                itemHeight: 80,
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
                selectedItem: '',
            };
        },
        computed: {
            ...mapState({
                selectedCase: (state) => state.Cases.selectedCase,
                selectedCaseId: (state) => state.Cases.selectedCaseId,
                channelConfig: (state) => state.System.userSettings.system.channels,
                statusTypesConfig: (state) => state.System.userSettings.system.statuses,
                timeline: (state) => state.Cases.caseTimeline,
                caseToLoad: (state) => state.Cases.caseToLoad,
                currentSelectedClient: (state) => state.Client.currentSelectedClient,
            }),

            timelineData() {
                return this.timeline?.cases;
            },

            timelineDataWithDates() {
                const cases = this.timeline?.cases || [];
                const result = [];

                if (cases.length > 0) {
                    let currentMonth = null;

                    for (const item of cases) {
                        const currentDateTime = item.data.dateTime;
                        const month = new Date(currentDateTime).getMonth();

                        if (currentMonth === null || currentMonth !== month) {
                            if (currentMonth !== null) {
                                result.push({
                                    type: 'month',
                                    month: month + 1,
                                });
                            }
                            currentMonth = month;
                        }

                        result.push(item);
                    }
                }

                return result;
            },

            locale() {
                return this.$i18n.locale;
            },

            filteredStatusTypes() {
                const statusBooleans = getActiveFromSettings(this.statusTypesConfig);
                return filterObjectsByKeys(statusBooleans, statusTypes, 'value');
            },

            filteredChannelTypes() {
                const channelBooleans = getActiveFromSettings(this.channelConfig);
                return filterObjectsByKeys(channelBooleans, channelTypes, 'value');
            },

            getVHeight() {
                const magicHeightFixer = 10;
                return this.numberOfItems * this.itemHeight + magicHeightFixer + 'px';
            },

            timelineExists() {
                return this.timelineData !== null;
            },
        },

        mounted() {
            useEventListener(window, 'resize', this.getDimensions);
        },

        methods: {
            getMonthByNumber,
            formatDateToText,
            ...mapActions({
                goToCase: 'Cases/goToCase',
            }),

            getContactTooltip(item) {
                return item.contactName || this.$t('timeLine.noContactPerson');
            },
            getTitle(data) {
                return data.topic || this.$t('timeLine.noTopic');
            },
            isSelectedCase(id) {
                // *** fix this to work on sockets instead ***
                if (!this.selectedCase?.case?.caseId) {
                    return false;
                }
                return id === this.selectedCase.case.caseId || id === this.caseToLoad;
            },
            getDimensions() {
                this.width = document.documentElement.clientWidth;
                this.height = document.documentElement.clientHeight;
            },
            getStatusColor(status) {
                switch (status) {
                    case caseStatuses.OPEN: {
                        return this.colors.color4;
                    }
                    case caseStatuses.CLOSED: {
                        return this.colors.color2;
                    }
                    case caseStatuses.ONGOING: {
                        return this.colors.color1;
                    }
                    case caseStatuses.DELETED: {
                        return this.colors.gray1;
                    }
                    case caseStatuses.IN_QUEUE: {
                        return this.colors.color3;
                    }
                    default: {
                        return this.colors.gray1;
                    }
                }
            },
            goTo(caseId) {
                this.goToCase({ caseId });
            },
            getIcon(type) {
                const { icon } = this.filteredChannelTypes.find((t) => t.value === type);
                if (icon !== undefined || icon !== null) {
                    return icon;
                }
                return 'mdi-email';
            },
            statusText(status) {
                const { locale } = this.$i18n;
                return this.filteredStatusTypes.find((s) => s.value === status).nameTranslate[locale];
            },
        },
    };
</script>
<style scoped lang="scss">
    .month {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
    }

    .month-text {
        font-size: 12px;
        font-weight: 400;
        color: var(--v-gray1-base);
    }
    .loader-container {
        background-color: white;
        height: 100%;
    }
    .outer-container {
        display: flex;
        flex: 1;
        display: flex;
        justify-content: stretch;
        flex-direction: column;
        overflow-y: hidden;
    }
    .virtual-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        flex: 1;
    }

    .font-username {
        font-size: 14px;
    }

    .scroll-item {
        position: relative;
    }
    .selected-item {
        width: 6px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: var(--v-primary-base);
    }
    .wrapper-list {
        position: absolute;
        height: 100%;
        top: 0;
        margin-left: 39px;
        width: 2px;
        z-index: 10;
        background-color: transparent;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        border-radius: 0px 0px 3px 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: white;
        // background: white;
        border-radius: 0px 0px 3px 0px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgba(231, 231, 233, 0.689);
        // border-radius: 0px 0px 0px 0px;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #a2a2a2;
    }

    .v-progress-circular {
        display: block;
    }

    .softShadowWhite {
        box-shadow:
            0px 4px 8px rgba(0, 0, 0, 0.07),
            0 0px 16px rgba(0, 0, 0, 0.05) !important;
        background-color: transparent !important;
    }

    .item-wrapper {
        padding: 0 16px;
        display: flex;
        align-items: center;
        height: 60px;
        position: relative;
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
        transition: background-color 0.2s ease-in;
    }

    .item-wrapper:hover {
        background-color: var(--v-gray5-base);
        cursor: pointer;
    }

    .item-wrapper:focus {
        background-color: var(--v-gray5-base);
    }

    .item {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }

    .type-icon {
        display: block;
    }

    .card-wrapper {
        display: flex;
        flex-grow: 1;
        border-radius: 3px;
        gap: 1em;
    }

    .date-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .card-icons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .timeline-heading {
        color: var(--v-gray4-base);
        font-size: 12px;
    }

    .card-avatar {
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rounded-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 24px;
        height: 24px;
    }

    span {
        font-size: 12px;
    }

    .item-date {
        font-size: 10px;
        color: var(--v-gray1-base);
    }

    .timeline-header-wrapper {
        display: grid;
        grid-template-rows: auto auto;
        width: 100%;
    }

    .timeline-header-wrapper > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
<i18n lang="json">
{
    "en": {
        "timeLine": {
            "dateTime": "Date / Time",
            "noAgent": "No agent",
            "noContactPerson": "No contact person",
            "noTopic": "No topic"
        }
    },
    "sv": {
        "timeLine": {
            "dateTime": "Datum / Tid",
            "noAgent": "Ingen agent",
            "noContactPerson": "Ingen kontaktperson",
            "noTopic": "Inget ämne"
        }
    }
}
</i18n>
