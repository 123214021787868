import axios from 'axios';
import { searchFields } from '../../enums/client.enums';

function searchClients(payload) {
    // '+' symbol == empty space in url-queries, thus we must do this replace to maintain it (phone numbers can have + symbols) -Teo 2022-06-14
    const formattedPayload = payload.replace('+', '%2B');
    return axios.get(`/clients?search=${formattedPayload}`);
}

function getAllClientsData(payload) {
    return axios.get('/clients', {
        params: {
            limit: 25,
            page: payload.page,
            search: payload.search,
            searchFields: [searchFields.NAME],
        },
    });
}

function getAllContactsData(payload) {
    return axios.get(
        `/contacts?clientId=${payload.clientId}&limit=${payload.limitOffSet || 0},${payload.limitAmount || 0}&search=${
            payload.search
        }&fields=${payload.fields}`,
    );
}
function addNewClient(payload) {
    return axios.post(`/customer-management/clients`, payload);
}

function addNewContact(payload) {
    return axios.post(`/contact`, payload);
}

function deleteClient(payload) {
    return axios.delete(`/clients/${payload}`);
}

function getClientTemplate() {
    return axios.get(`/client/manifest/template`);
}

function getContactTemplate() {
    return axios.get(`/contact/manifest/template`);
}

function updateContactInfo(payload) {
    return axios.put(`/contact/${payload.id}`, payload);
}

function setDefaultCreateClient(payload) {
    return axios.post(`/clients/defaultData`, payload);
}
function getDefaultCreateClient() {
    return axios.get(`/clients/defaultData/all`);
}
function generateClientId() {
    return axios.get(`/clients/generate/id`);
}

function getContactPersonExtraData(id) {
    return axios.get(`/contact/${id}`);
}

function getClientExtraData(id) {
    return axios.get(`/customer/${id}`);
}

function getAvailableUsers(payload) {
    return axios.get(
        `/customer-management/users/available/${payload.objectId}?page=${payload.page}&search=${payload.search}`,
    );
}

function addUsersToClient(payload) {
    return axios.post(`/customer-management/users`, payload);
}

function getMembers(payload) {
    return axios.get(
        `/customer-management/users/members/${payload.objectId}?page=${payload.page}&search=${payload.search}`,
    );
}

function removeAllMembers(payload) {
    return axios.delete(`/customer-management/users/${payload.objectId}`);
}

function removeMember(payload) {
    return axios.delete(`/customer-management/user/${payload.objectId}/${payload.userId}`);
}

function updateClient(payload) {
    return axios.put(`/customer-management/clients/${payload.id}`, payload.body);
}

function checkClient(payload) {
    return axios.post(`/customer-management/clients/check`, payload);
}

export default {
    searchClients,
    getAllClientsData,
    getAllContactsData,
    addNewClient,
    addNewContact,
    deleteClient,
    getClientTemplate,
    getContactTemplate,
    updateContactInfo,
    setDefaultCreateClient,
    getDefaultCreateClient,
    generateClientId,
    getClientExtraData,
    getContactPersonExtraData,
    getAvailableUsers,
    addUsersToClient,
    getMembers,
    removeAllMembers,
    removeMember,
    updateClient,
    checkClient,
};
