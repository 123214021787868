import axios from 'axios';

function getHealthCheck() {
    return axios.get(`/system/health`);
}

async function getNewVersion() {
    try {
        // Use fetch because we don't want to send any auth headers and similar
        // Use no url because we want to fetch the current html page we are on right neow
        const response = await fetch('');
        const body = await response.text();
        const parser = new DOMParser();
        const dom = parser.parseFromString(body, 'text/html');
        const versionTag = dom.querySelector('meta[version]');
        return versionTag.getAttribute('version');
    } catch {
        // If we fail, just return whichever version we built with
        return process.env.VUE_APP_VERSION;
    }
}

export default {
    getHealthCheck,
    getNewVersion,
};
