import axios from 'axios';
import DedupeRequest from '../../entity/DedupeRequest';

const getBrandsDedupe = new DedupeRequest((params) => {
    return axios.get('brands', { params });
}, 1000);

function getBrands(params) {
    return getBrandsDedupe.send(params);
}

function getBrandsCount() {
    return axios.get('brands/count');
}

const getBrandDedupe = new DedupeRequest((id) => {
    return axios.get(`brands/${id}`);
}, 1000);

function getBrand(id) {
    return getBrandDedupe.send(id);
}

const getBrandLogoDedupe = new DedupeRequest((id, params) => {
    return axios.get(`brands/${id}/logo`, { params });
}, 1000);

function getBrandLogo(id, params) {
    return getBrandLogoDedupe.send(id, params);
}

function createBrand(payload) {
    return axios.post('brands', payload);
}
function updateBrand(id, fields) {
    return axios.patch(`brands/${id}`, fields);
}

function deleteBrand(id, toBrandId) {
    return axios.delete(`brands/${id}`, { params: { toBrandId } });
}

function getUserSelectedBrands(userId) {
    return axios.get(`brands/users/${userId}`);
}

function updateUserSelectedBrands(userId, brandIds) {
    return axios.patch(`brands/users/${userId}`, { brandIds: brandIds });
}

function fillBrandWithQueues(brandId) {
    return axios.post(`brands/${brandId}/fill-queues`);
}

export default {
    getBrands,
    getBrandsCount,
    getBrand,
    getBrandLogo,
    createBrand,
    updateBrand,
    deleteBrand,
    getUserSelectedBrands,
    updateUserSelectedBrands,
    fillBrandWithQueues,
};
