<template>
    <secondary-action-button :dense="false" :disabled="isDisabled" @click="openWindow">
        <img class="button-icon" :src="icon" />
        <span class="name-container">
            {{ $t('federatedLogin.continueWith', { name: item.name }) }}
        </span>
    </secondary-action-button>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    import cfg from '@/app/config';

    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {};
        },
        computed: {
            ...mapState('Auth', ['serviceLoggingIn', 'serviceLoggingOut']),

            icon() {
                return require(`@/assets/FederatedLogin/Icons/${this.item.icon}.svg`);
            },

            currentServiceLoggingIn() {
                return this.serviceLoggingIn === this.item.identifier;
            },

            currentServiceLoggingOut() {
                return this.serviceLoggingOut === this.item.identifier;
            },

            loggingIn() {
                return this.serviceLoggingIn.length > 0;
            },

            loggingOut() {
                return this.serviceLoggingOut.length > 0;
            },

            isLoading() {
                return this.currentServiceLoggingIn || this.currentServiceLoggingOut;
            },

            isDisabled() {
                return (
                    this.loggingIn || this.loggingOut || this.currentServiceLoggingIn || this.currentServiceLoggingOut
                );
            },
        },
        created() {
            this.checkForFederatedLoginResponse();
        },
        methods: {
            ...mapActions('Auth', ['checkForFederatedLoginResponse']),
            openWindow() {
                window.location.href = `${cfg.customerFirstUrl}auth/${this.item.identifier}/login`;
            },
        },
    };
</script>
<style scoped lang="scss">
    .button {
        transition: 0.2s;
        box-shadow: none;
        height: 56px !important;
        width: 100%;
        padding: 100px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border-radius: 4px;
        border-color: var(--v-gray3-base);
        border-width: 2px;
    }
    .button:hover {
        transition: 0.2s;
        border-color: var(--v-gray3-darken1);
    }
    .button-icon {
        width: 14px;
        height: 14px;
        margin: auto 10px;
    }
    .name-container {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--v-gray2-base);
        padding-left: 6px;
        padding-right: 12px;
        text-align: center;
        font-size: 1rem;
    }
</style>
<i18n lang="json">
{
    "en": {
        "federatedLogin": {
            "continueWith": "Continue with {name}",
            "loggingIn": "Logging in..."
        }
    },
    "sv": {
        "federatedLogin": {
            "continueWith": "Fortsätt med {name}",
            "loggingIn": "Loggar in..."
        }
    }
}
</i18n>
